import React, { useEffect, useState, useRef, useCallback } from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import { navigate } from '@reach/router';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
  background-color: #d9d9d9;
  background-size: cover;
  background-position: center;

  margin: 110px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 901px) {
    margin-top: 110px;
  }

  .header-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;

    font-family: 'Oswald', sans-serif;
    font-weight: 200;
    color: white;
    text-align: center;
    padding: 0 31px;
  }

  .wrap-hex {
    border: 5px;
    position: relative;
    width: 100%;
    text-align: center;
    height: 244px;
    .hex {
      width: 344px;
      margin-left: -172px;
      margin-top: -54px;
      position: absolute;
      top: 0;
      left: 50%;
    }
    .letters {
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      margin-top: 84px;
    }
  }
  h1 {
    margin: 13px auto 0;
    font-family: Oswald;
    font-style: normal;
    font-weight: 200;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 25px;
  }
  h2,
  h3 {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    display: inline;
  }
  @media screen and (max-width: 402px) {
    .header-content-wrapper {
      padding: 0;
    }
  }
`;

const Intro = styled.div`
  margin-left: 40px;
  margin-right: 40px;
  .intro-copy {
    max-width: 1078px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 34px;
    padding-bottom: 54px;
  }
  h3 {
    font-weight: 300;
    font-size: 32px;
    line-height: 42px;
    font-weight: 300;
    font-size: 32px;
    color: #067eba;
  }
  p {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 24px;
    color: #000000;
  }
  @media screen and (max-width: 1133px) {
    .intro-copy {
      max-width: 714px;
    }
  }
`;

const Grid = styled.div`
  max-width: calc(1078px + 40px);
  margin-left: auto;
  margin-right: auto;
  .wrapper {
    display: flex;
    flex-flow: wrap;
    place-content: flex-start;
    align-items: flex-start;
    margin: -7px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .item.with-link {
    cursor: pointer;
  }
  .item {
    display: flex;
    align-items: center;
    max-width: 350px;
    width: 33.331%;
    position: relative;
    margin: 7px;
    background-color: #d9d9d9;
    width: 350px;
    height: 350px;
    background-position: center;
    justify-content: center;
  }
  .item.with-link:hover::after {
    background-color: rgba(0, 0, 0, 0.55);
  }
  .item.with-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0);
    transition: all 380ms ease-in-out 0s;
    cursor: pointer;
  }
  .item:hover a.itemLink {
    z-index: 11;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a:link,
  a:visited,
  a:active {
    color: white;
  }
  .item a.itemLink {
    inset: 0px;
    z-index: -11;
    transition: all 380ms ease-in-out 0s;
    text-decoration: none;
  }
  .item button {
    background: transparent;
    border: 1px solid #fff;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Oswald;
    font-size: 21px;
    font-variant-ligatures: none;
    letter-spacing: 3.3px;
    margin-bottom: 7px;
    padding: 7px 27px;
    text-decoration: none;
    transition: all 0.2s linear;
  }
  .item button:hover {
    text-decoration: underline;
  }

  .item:nth-child(1) {
    background-color: #44c3e7;
  }
  .item:nth-child(5) {
    background-color: #a9d23f;
  }
  .item:nth-child(7) {
    background-color: #ffc72c;
  }

  p.callout {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: #000000;
    margin-left: 16%;
    margin-right: 16%;
  }
  a:not(.itemLink) {
    color: #000000;
  }
  @media screen and (max-width: 1133px) {
    max-width: calc(714px + 40px);
  }
  @media screen and (max-width: 980px) {
    max-width: calc(350px + 40px);
    .wrapper {
      margin: 0;
    }
  }
`;

const Contact = styled.div`
  max-width: 798px;
  padding-left: 30px;
  padding-right: 30px;
  /* padding-top: 68px;
  padding-bottom: 78px; */
  padding: 54px 0;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  h3 {
    font-weight: 300;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    color: #067eba;
    margin-bottom: 42px;
  }
  .contact-text,
  .contact-text a {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #000000;
  }
`;

const Services = styled.div`
  background: linear-gradient(7.78deg, #172e56 21.07%, #067eba 180.43%);
  padding-top: 68px;
  padding-bottom: 68px;
  .columns {
    display: flex;
    max-width: 711px;
    padding-left: 70px;
    margin-left: auto;
    margin-right: auto;
  }
  .column-left {
    display: inline-block;
    width: 45%;
    padding-right: 30px;
    border-right: 1px solid #ffffff;
  }
  .column-right {
    display: inline-block;
    width: 55%;
    padding-left: 30px;
  }
  h1 {
    font-weight: 300;
    font-size: 48px;
    text-align: center;
    color: #44c3e7;
    margin-bottom: 45px;
  }
  p,
  li {
    font-size: 24px;
    line-height: 34px;
    color: #ffffff;
  }
  li {
    font-weight: 500;
  }
  /* @media screen and (max-width: 920px) { */
  @media screen and (max-width: 900px) {
    h1 {
      width: calc(100% - 60px);
      margin-left: auto;
      margin-right: auto;
    }
    .columns {
      display: block;
      padding-left: 0px;
    }
    .column-left {
      width: calc(100% - 60px);
      padding-left: 30px;
      border-right: none;
      border-bottom: 1px solid #ffffff;
    }
    .column-right {
      width: calc(100% - 60px);
      padding-right: 30px;
    }
  }
`;
const FormGroup = styled.div`
  background: white;
  padding-bottom: 5rem;
  /* padding-top: 80px; */
  padding-bottom: 0;
  color: black;
  h1.formheader {
    font-family: 'Oswald';
    font-size: 1.9rem;
    line-height: 1.6;
    margin: 0px auto 30px;
    color: rgb(0, 155, 206);
    font-weight: 400;
    letter-spacing: 1.4px;
    transition: all 200ms linear 0s;
    max-width: 825px;
    padding-top: 80px;
  }
  p {
    margin: 0 auto 15px;
    padding: 0 30px;
    font-family: 'Roboto';
    font-weight: 300;
    letter-spacing: 1.3px;
    font-size: 1.25rem;
    line-height: 1.55;
    max-width: 825px;
  }
  .form-heading {
    font-weight: 300;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    color: #067eba;
    margin-bottom: 42px;
    margin: 0 auto 15px;
    padding: 0 30px;
    font-family: 'Oswald';
    letter-spacing: 1.3px;
    max-width: 825px;
  }
  .sending,
  .success,
  .fail {
    text-align: center;
  }

  .sending {
    animation: scalebounce 2400ms ease-in-out 0s infinite, fadeintext 400ms linear forwards;
  }

  @keyframes scalebounce {
    0% {
      transform: scale(0.7);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(0.7);
    }
  }
`;
const StyledForm = styled.form`
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 30px 20px 0;
  display: ${(props) => (props.formstate === 'READY' ? 'grid' : 'none')};
  grid-template-columns: 1fr 1fr;
  .error {
    font-family: 'Roboto';
    font-weight: 300;
    letter-spacing: 1.3px;
    font-size: 1rem;
    line-height: 1.25;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
    max-height: 60px;
    @media only screen and (max-width: 600px) {
      align-items: flex-start;
    }
  }
  .input-wrapper {
    display: inline;
    position: relative;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    input[type='text'] {
      width: 100%;
    }
    &:nth-of-type(1),
    &:nth-of-type(3) {
      input[type='text'] {
        width: 96%;
      }
    }
    @media only screen and (max-width: 600px) {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        input[type='text'] {
          width: 100%;
        }
      }
    }
  }
  input[type='text'] {
    max-width: 100%;
    width: 48%;
    padding: 1rem;
    font-family: 'Roboto';
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 0.9px;
    background-color: #00a3d6; //TODO: non-standard BLUE
    color: white;
    border: none;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    border-radius: 0px;
  }
  input[name='name'],
  input[name='email'] {
    margin-right: 4%;
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    input[name='name'],
    input[name='email'] {
      margin-right: 0;
    }
  }
  textarea {
    width: 100%;
    padding: 1rem;
    font-family: 'Roboto';
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 0.9px;
    height: 7rem;
    border: none;
    background-color: #00a3d6; //TODO: non-standard BLUE
    color: white;
    box-sizing: border-box;
    border-radius: 0px;
    grid-column: 1/3;
    @media only screen and (max-width: 600px) {
      grid-column: 1;
    }
  }
  input[type='text']::placeholder,
  textarea::placeholder {
    color: white;
    opacity: 0.6;
  }
  input[type='text']:placeholder-shown,
  textarea:placeholder-shown {
    text-transform: uppercase;
  }
  input[type='submit'] {
    font-family: 'Oswald';
    display: block;
    margin: 2rem auto;
    margin-bottom: 0;
    padding: 0.5rem 2rem;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 2px;
    background-color: black;
    border: 1px solid #00a3d6;
    color: #00a3d6;
    -webkit-appearance: none;
    border-radius: 0px;
    background: transparent;
    grid-column: 1/3;
    &:hover {
      text-decoration: underline;
      outline: 1px solid #00a3d6;
    }
    @media only screen and (max-width: 600px) {
      grid-column: 1;
    }
  }
`;

const Brands = styled.div`
  max-width: calc(1198px + 50px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 25px;
  padding-bottom: 67px;
  h3 {
    font-weight: 300;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    color: #067eba;
    margin-bottom: 42px;
  }
  .wrapper {
    display: flex;
    flex-flow: wrap;
    place-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    margin-left: -25px;
    margin-right: -25px;
    padding-right: 25px;
    padding-left: 25px;
  }
  .item {
    position: relative;
    display: flex;
    align-items: center;
    width: 228px;
    height: 130px;
    margin-bottom: 23px;
    margin-left: 25px;
    margin-right: 25px;
    justify-content: center;
    width: 198px;
    height: 113px;
    background-size: contain;
  }

  @media screen and (max-width: 1297px) {
    max-width: 84vw;
  }
`;

const Phone = styled.div`
  padding: 37px 0;
  background: #067eba;
  .phone-text,
  .phone-text a {
    font-size: 24px;
    text-align: center;
    color: #ffffff;
    width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
  }
`;

const PageTemplate = ({ className, fields }) => {
  // const pageData = data.allIndustryLandingPageHJson;
  const contactForm = useRef();
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [formState, setFormState] = useState('READY');

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation(); //Need to stop propagation to prevent the GA4 enhanced measurement from firing its own form_submit event
      window.dataLayer = window.dataLayer || [];

      const name = contactForm.current.querySelector('input[name=name]');
      const phone = contactForm.current.querySelector('input[name=phone]');
      const email = contactForm.current.querySelector('input[name=email]');
      const business = contactForm.current.querySelector('input[name=business]');
      const message = contactForm.current.querySelector('textarea[name=message]');

      let validationErrors = false;

      if (name.value.length === 0) {
        setNameError('Please provide your name.');
        validationErrors = true;
      } else if (name.value.split(' ').length < 2) {
        setNameError('Please provide your first and last name.');
        validationErrors = true;
      } else {
        setNameError(false);
      }

      if (phone.value.length === 0) {
        setPhoneError('Please provide your phone number.');
        validationErrors = true;
      } else {
        setPhoneError(false);
      }

      if (email.value.length === 0) {
        setEmailError('Please provide your email address.');
        validationErrors = true;
      } else {
        setEmailError(false);
      }

      if (validationErrors) {
        window.dataLayer.push({
          event: 'form_error',
          form_name: 'contact',
          form_event_source: 'Industry Landing Page',
          error_type: 'Field validation error',
        });
        return false;
      }
      const nameparts = name.value.split(' ');
      const firstname = nameparts[0];
      const lastname = nameparts[1];
      contactForm.current.querySelector('input[name=firstname]').value = firstname;
      contactForm.current.querySelector('input[name=lastname]').value = lastname;
      setFormState('SENDING');

      (async () => {
        try {
          const result = await ky
            .post('https://usebasin.com/f/8d56d45aa45a.json', {
              json: {
                name: name.value,
                phone: phone.value,
                email: email.value,
                business: business.value,
                message: message.value,
              },
            })
            .json();

          // console.log(result);
          window.dataLayer.push({
            event: 'form_submit',
            form_name: 'contact',
            form_event_source: 'Industry Landing Page',
          });

          if (result.success === true) {
            setFormState('SUCCESS');
          } else {
            window.dataLayer.push({
              event: 'form_error',
              form_name: 'contact',
              form_event_source: 'Industry Landing Page',
              error_type: 'Basin acknowledgement is not success',
            });
            setFormState('FAIL');
          }
        } catch (error) {
          window.dataLayer.push({
            event: 'form_error',
            form_name: 'contact',
            form_event_source: 'Industry Landing Page',
            error_type: 'AJAX Post failure',
          });
          setFormState('FAIL');
          return;
        }
      })();
    },
    [contactForm]
  );
  return (
    <>
      <div className="industry-landing-content">
        <HeaderWrapper
          style={{
            backgroundImage: `url('${fields.bgImage.publicURL}')`,
          }}
        >
          <div className="header-content-wrapper">
            <h1>{fields.title}</h1>
            <div>
              <h2>{fields.subTitle}</h2>
            </div>
          </div>
        </HeaderWrapper>

        <Intro className="intro">
          <div className="intro-copy">
            <h3>{fields.intro_title}</h3>
            <p>{fields.intro}</p>
          </div>
        </Intro>
        <Grid className="grid">
          {/* {' '} */}
          <div className="wrapper">
            {fields.grid_items.map((item, index) => (
              <div
                key={index}
                className={'item' + (item.link ? ' with-link' : '')}
                style={
                  item.bgcolor
                    ? {
                        backgroundColor: item.bgcolor,
                      }
                    : { backgroundImage: `url('${item.image.publicURL}')` }
                }
              >
                {item.text ? <p className="callout" dangerouslySetInnerHTML={{ __html: item.text }}></p> : <></>}
                {item.link ? (
                  <a href={item.link} className="itemLink">
                    <button className="cta">Learn More</button>
                  </a>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
        </Grid>
        {/* <Contact className="contact">
          <h3>{fields.contact_title}</h3>
          <div className="contact-text" dangerouslySetInnerHTML={{ __html: fields.contact }}></div>
        </Contact> */}
        <Contact className="contact">
          <FormGroup>
            {/* <h1 className="formheader">A unique, comfortable environment is an important part of what we offer our clients and business partners. </h1> */}
            <div className="formcontent">
              <p className="form-heading">Interested in seeing how we can drive results for you?</p>
            </div>
            <StyledForm
              method="POST"
              action=""
              formstate={formState}
              id="contact-form"
              ref={contactForm}
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form" value="contact" />
              <input type="hidden" name="firstname" />
              <input type="hidden" name="lastname" />
              <div className="input-wrapper">
                {nameError !== false ? <div className="error">{nameError}</div> : null}
                <input type="text" size="35" name="name" placeholder="Name" />
              </div>
              <div className="input-wrapper">
                {phoneError !== false ? <div className="error">{phoneError}</div> : null}
                <input type="text" size="35" name="phone" placeholder="Phone" />
              </div>
              <div className="input-wrapper">
                {emailError !== false ? <div className="error">{emailError}</div> : null}
                <input type="text" size="35" name="email" placeholder="Email" />
              </div>
              <div className="input-wrapper">
                <input type="text" size="35" name="business" placeholder="Business" />
              </div>
              <textarea rows="8" cols="25" name="message" placeholder="Message" />
              <input type="submit" value="Submit" />
            </StyledForm>
            {formState === 'SENDING' && (
              <div className="sending">
                <p>Sending...</p>
              </div>
            )}
            {formState === 'SUCCESS' && (
              <div className="success">
                <p>Your message has been sent! We will be contacting you shortly.</p>
              </div>
            )}
            {formState === 'FAIL' && (
              <div className="fail">
                <p>Uh oh! Something went wrong. Please try again later, or give us a call at 920-983-9700.</p>
              </div>
            )}
          </FormGroup>
        </Contact>
        <Services className="services">
          <h1>{fields.services_title}</h1>
          <div className="columns">
            <div className="column-left">
              <p>{fields.services}</p>
            </div>
            <div className="column-right">
              <ul>
                <li>{fields.services_list_1}</li>
                <li>{fields.services_list_2}</li>
                <li>{fields.services_list_3}</li>
                <li>{fields.services_list_4}</li>
                <li>{fields.services_list_5}</li>
                <li>{fields.services_list_6}</li>
                <li>{fields.services_list_7}</li>
                <li>{fields.services_list_8}</li>
                <li>{fields.services_list_9}</li>
              </ul>
            </div>
          </div>
        </Services>
        <Brands className="brands">
          <h3>{fields.brands_title}</h3>
          <div className="wrapper">
            {fields.brands_items.map((item, index) => (
              <div
                key={index}
                className="item img"
                style={{
                  backgroundImage: `url('${item.img.publicURL}')`,
                }}
              ></div>
            ))}
          </div>
        </Brands>
        {/* <Phone className="phone">
          <div className="phone-text" dangerouslySetInnerHTML={{ __html: fields.phone }}></div>
        </Phone> */}
      </div>
    </>
  );
};

const Page = ({ data }) => {
  if (data.allAllIndustryLandingPagesHJson.edges[0].node) {
    const page = data.allAllIndustryLandingPagesHJson.edges[0].node;
    const meta = data.site.siteMetadata;

    return (
      <Layout>
        <Helmet>
          <title>{page.meta_title}</title>
          <meta name="description" content={page.meta_desc} />
          <meta property="og:title" content={page.meta_title} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={page.meta_desc} />
          {page.meta_ogimg && <meta property="og:image" content={meta.siteUrl + page.meta_ogimg.publicURL} />}
          <meta property="og:url" content={meta.siteUrl + '/' + page.path + '/'} />
        </Helmet>
        <PageTemplate className="casestudy-page" fields={page} />
      </Layout>
    );
  } else {
    return <></>;
  }
};

export default Page;

export const pageQuery = graphql`
  query IndustryPageByName($lpName: String!) {
    allAllIndustryLandingPagesHJson(filter: { path: { eq: $lpName } }) {
      edges {
        node {
          path
          title
          meta_title
          meta_desc
          meta_ogimg {
            publicURL
          }
          intro_title
          intro
          subTitle
          bgImage {
            publicURL
          }
          grid_items {
            image {
              publicURL
            }
            text
            bgcolor
            alt
            link
          }
          contact_title
          contact
          services_title
          services
          services_list_1
          services_list_2
          services_list_3
          services_list_4
          services_list_5
          services_list_6
          services_list_7
          services_list_8
          services_list_9
          brands_title
          brands_items {
            img {
              publicURL
            }
          }
          phone
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
